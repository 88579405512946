import { configureStore } from '@reduxjs/toolkit';
import { projectScreenSlice } from './slices/projectScreenSlice';

const store = configureStore({
  reducer: {
    projects: projectScreenSlice.reducer,
  },
});

export default store;

import axios from 'axios';
import { useRef, useState } from 'react';
import { localStorageName } from './constants';
import API_URL from './constants';
import { notification } from 'antd';

const auth = localStorage.getItem(localStorageName);

if (!auth && window.location.pathname !== '/login') {
  window.location.href = '/login';
}

const authObject = JSON.parse(auth);

console.log('🚀 ~ file: axios.js ~ line 16 ~ authObject', authObject);
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    authorization: authObject ? `Bearer ${authObject.token}` : '',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.removeItem(localStorageName);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export const useAxiosGet = (url, params = {}, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lastParams = useRef(params);

  const request = async (queryParams = lastParams.current) => {
    const mergeQueryParams = {
      ...lastParams.current,
      ...queryParams,
    };

    try {
      setLoading(true);
      const response = await axiosInstance.get(url, {
        params: mergeQueryParams,
        headers,
      });
      setData(response.data);
      setError(null);
      lastParams.current = mergeQueryParams;
      return response.data;
    } catch (err) {
      setError(err);
      notification.error({
        message: err.response.status,
        description: err.response.data.msg,
        placement: 'topRight',
      });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData,
    loading,
    error,
    request,
    lastParams: lastParams.current,
  };
};

export const useAxiosPost = (url, body, params = {}, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (
    bodyParams = body,
    queryParams = params,
    headersParams = headers,
  ) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(url, bodyParams, {
        params: queryParams,
        headersParams,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      notification.error({
        message: err.response.status,
        description: err.response.data.msg,
        placement: 'topRight',
      });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData,
    loading,
    error,
    request,
  };
};

export const UploadImage = async ({ file }) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const newSubmission = await axios.post(
      `${API_URL}/image_upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return newSubmission.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

import React, { Suspense } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Layout, ConfigProvider, Button, Typography, Menu } from 'antd';
import { localStorageName } from './configs/constants';
import LoginScreen from './screens/loginScreen/LoginScreen';
import routes from './routes';
import './App.css';
import ARFcategories from './assets/ARFcategories.png';
import ARFcategoriesFilled from './assets/ARFcategoriesFilled.png';
import ARFsubcategories from './assets/ARFsubcategories.png';
import ARFsubcategoriesFilled from './assets/ARFsubcategoriesFilled.png';
import ARFcategorySubcategories from './assets/ARFcategorySubcategories.png';
import ARFcategorySubcategoriesFilled from './assets/ARFcategorySubcategoriesFilled.png';
import ARFprojectCategories from './assets/ARFprojectCategories.png';
import ARFprojectCategoriesFilled from './assets/ARFprojectCategoriesFilled.png';
import ARFhome from './assets/ARFhome.png';
import ARFhomeFilled from './assets/ARFhomeFilled.png';

const { Header, Content } = Layout;

const menuItems = [
  {
    path: '/',
    label: 'بداية',
    icon: { default: ARFhome, filled: ARFhomeFilled },
  },
  {
    path: '/categories',
    label: 'فئات',
    icon: { default: ARFcategories, filled: ARFcategoriesFilled },
  },
  {
    path: '/project-categories',
    label: 'فئات المشروع',
    icon: { default: ARFprojectCategories, filled: ARFprojectCategoriesFilled },
  },
  {
    path: '/subcategories',
    label: 'الفئات-الفرعية',
    icon: { default: ARFsubcategories, filled: ARFsubcategoriesFilled },
  },
  {
    path: '/category-subcategories',
    label: 'فئة الفئات-الفرعية',
    icon: {
      default: ARFcategorySubcategories,
      filled: ARFcategorySubcategoriesFilled,
    },
  },
];

const App = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (pathname === '/login') return <LoginScreen />;

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: '#1B76CE' } }}
      direction="ltr"
    >
      <Layout>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#1C1C1E',
            padding: '0 20px',
          }}
        >
          <Menu
            mode="horizontal"
            selectedKeys={[pathname]}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-evenly',
              background: '#1C1C1E',
              border: 'none',
            }}
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.path}
                style={{
                  padding: '0 20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => navigate(item.path)}
                icon={
                  <img
                    src={
                      pathname === item.path
                        ? item.icon.filled
                        : item.icon.default
                    }
                    alt={item.label}
                    style={{ width: '24px', height: '24px' }}
                  />
                }
              >
                <Typography.Text
                  style={{
                    color: pathname === item.path ? '#1B76CE' : '#A2A2A7',
                  }}
                >
                  {item.label}
                </Typography.Text>
              </Menu.Item>
            ))}
          </Menu>
          <Button
            style={{
              border: 'none',
              color: '#ebebf5',
              background: '#1B76CE',
              maxWidth: '90px',
            }}
            onClick={() => {
              localStorage.removeItem(localStorageName);
              window.location.href = '/login';
            }}
          >
            الخروج
          </Button>
        </Header>
        <Content
          style={{ padding: 24, minHeight: '100vh', background: 'black' }}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <route.component />
                  </Suspense>
                }
              />
            ))}
          </Routes>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
};

export const projectScreenSlice = createSlice({
  name: 'projectScreen',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
  },
});

export const { setProjects } = projectScreenSlice.actions;

export default projectScreenSlice.reducer;

import { lazy } from 'react';

const Home = lazy(() => import('./screens/homeScreen/HomeScreen'));
const UpdateProject = lazy(
  () => import('./screens/updateProjectScreen/UpdateProjectScreen'),
);

const CreateProject = lazy(
  () => import('./screens/createProjectScreen/CreateProjectScreen'),
);
const Categories = lazy(
  () => import('./screens/categoriesScreen/CategoriesScreen'),
);
const Subcategories = lazy(
  () => import('./screens/subcategoriesScreen/SubcategoriesScreen'),
);
const projectCategories = lazy(
  () => import('./screens/projectCategoriesScreen/ProjectCategoriesScreen'),
);

const CategorySubcategories = lazy(
  () =>
    import('./screens/categorySubcategoriesScreen/CategorySubcategoriesScreen'),
);

const ProfileScreen = lazy(
  () => import('./screens/profileScreen/ProfileScreen'),
);

const routes = [
  {
    key: Math.random(),
    path: '/',
    component: Home,
    label: 'Home',
  },
  {
    key: Math.random(),
    path: '/update-project',
    component: UpdateProject,
    label: 'Update Project',
  },
  {
    key: Math.random(),
    path: '/create-project',
    component: CreateProject,
    label: 'Create Project',
  },
  {
    key: Math.random(),
    path: '/categories',
    component: Categories,
    label: 'Categories',
  },
  {
    key: Math.random(),
    path: '/subcategories',
    component: Subcategories,
    label: 'Subcategories',
  },
  {
    key: Math.random(),
    path: '/project-categories',
    component: projectCategories,
    label: 'Project Categories',
  },
  {
    key: Math.random(),
    path: '/category-subcategories',
    component: CategorySubcategories,
    label: 'Category Subcategories',
  },
  {
    key: Math.random(),
    path: '/profile',
    component: ProfileScreen,
    label: 'Profile',
  },
];

export default routes;

/* eslint-disable no-restricted-globals */
import { Button, Form, Input, Typography, notification } from 'antd';
import React from 'react';
import useLocalStorage from '../../configs/localStorage';
import { useAxiosPost } from '../../configs/axios';
import './LoginScreen.css';
import loginLottie from '../../assets/loginLottie.gif';
import { localStorageName } from '../../configs/constants';
import { Navigate } from 'react-router-dom';

const LoginScreen = () => {
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const {
    request: loginRequest,
    loading: loginLoading,
    data: loginData,
    error: loginError,
  } = useAxiosPost('/auth/Login');

  const onLogin = async (values) => {
    try {
      await loginRequest({
        username: values.username,
        password: values.password,
      });

      setAuth({
        token: loginData.token,
        user: loginData.user,
      });
      location.replace('/');
    } catch (error) {
      notification.error({
        message: "Can't login",
        description: error?.response?.data?.message,
        placement: 'bottomRight',
      });

      console.log('🚀 ~ file: Login.jsx:27 ~ onLogin ~ error', error);
    }
  };
  if (auth) {
    return <Navigate to="/" />;
  }
  return (
    <main className="main-login">
      <Typography.Title>
        <span
          style={{
            color: '#A2A2A7',
          }}
        >
          Andira River Finance System
        </span>
      </Typography.Title>
      <div className="form-container">
        <div className="form">
          <h1
            style={{
              fontSize: '40px',
              fontWeight: 'bold',
              color: '#ebebf5',
            }}
          >
            Login
          </h1>
          <p
            style={{
              fontWeight: 'bold',
              opacity: 0.5,
              fontSize: '20px',
              color: '#ebebf5',
            }}
          >
            Enter your credentials
          </p>
          <Form
            layout="vertical"
            style={{
              width: '100%',
              marginTop: '50px',
            }}
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input
                className="login-input"
                size="large"
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              name="password"
            >
              <Input
                className="login-input"
                size="large"
                placeholder="Password"
                type="password"
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              style={{
                width: '100%',
                borderRadius: '40px',
                backgroundColor: '#1B76CE',
              }}
              loading={loginLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
          <div
            style={{
              color: 'red',
              marginTop: '10px',
            }}
          >
            {loginError && loginError.response?.data?.msg}
          </div>
        </div>
        <div>
          <img
            src={loginLottie}
            style={{
              width: '20vw',
              height: '20vw',
              objectFit: 'contain',
            }}
            alt=""
          />
        </div>
      </div>
    </main>
  );
};

export default LoginScreen;
